import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Avatar, Slide, useScrollTrigger} from "@mui/material";
import {Link} from "react-router-dom";
import {
    NAVBAR_BACKGROUND_COLOR,
    NAVBAR_TITLE_FONT_COLOR,
    NAVBAR_LINK_FONT_COLOR,
    NAVDRAWER_OPEN_ICON_COLOR,
    NAVDRAWER_BACKGROUND_COLOR,
    NAVDRAWER_LINK_FONT_COLOR, NAVDRAWER_LOGO_COLOR
} from "../../COLOR_THEME";
import {PortfolioIcon} from "../../constants/icons/PortfolioIcon";

const baseUrl = process.env.PUBLIC_URL
const drawerWidth = 240;

const navItems = [
    {
        label: "about",
        route: "about"
    },
    {
        label: "projects",
        route: "projects"
    }
]

export default function NavBar({content}) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const trigger = useScrollTrigger();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle}
             sx={{textAlign: 'center', background: NAVDRAWER_BACKGROUND_COLOR, color: NAVDRAWER_LINK_FONT_COLOR}}>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton component={Link} to={item.route} sx={{textAlign: 'center'}}>
                            <ListItemText primary={item.label}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar sx={{background: NAVBAR_BACKGROUND_COLOR}} component="nav">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{mr: 2, display: {sm: 'none'}, color: NAVDRAWER_OPEN_ICON_COLOR}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Avatar sx={{display: {xs: 'none', md: 'flex'}, mr: 1}} alt="Kevin N"
                                src={baseUrl + '/assets/img/avatar.jpg'}/>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
                            <Typography
                                variant="h5"
                                component="div"
                                color={NAVBAR_TITLE_FONT_COLOR}
                            >
                                <PortfolioIcon color={NAVDRAWER_LOGO_COLOR}/>⠀K.N. Portfolio
                            </Typography></Box>
                        <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                            {navItems.map((item) => (
                                <Button key={item.label} component={Link} to={item.route}
                                        sx={{color: NAVBAR_LINK_FONT_COLOR}}>
                                    <h4> {item.label}</h4>
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
            </Slide>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    PaperProps={{
                        sx: {
                            background: NAVDRAWER_BACKGROUND_COLOR,
                        }
                    }}
                    sx={{
                        color: NAVDRAWER_LINK_FONT_COLOR,
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <div>
                <Toolbar/>
                {content}
            </div>
        </Box>
    );
}

