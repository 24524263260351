import './App.css';
import NavBar from "./structures/nav/NavBar";
import {Routes} from "./Routes";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {APP_BACKGROUND_COLOR} from "./COLOR_THEME";

const theme = createTheme({
    palette: {
        background: {
            default: APP_BACKGROUND_COLOR
        }
    },
    typography: {
        allVariants: {
            fontFamily: 'ubuntu',
        },
        h6: {
            "fontWeight": 500,
            "fontSize": 16
        },
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <header className="App-header">
                    <Box>
                        <NavBar content={(<Routes/>)}></NavBar>
                    </Box>
                </header>
            </div>
        </ThemeProvider>
    );
}

export default App;
