import {ProjectsPage} from "./pages/ProjectsPage";
import {AboutPage} from "./pages/AboutPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {Routes as ReactRoutes, Route} from "react-router-dom";

export const Routes = () => {
    return (
        <ReactRoutes>
            <Route path="/" element={<AboutPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/projects" element={<ProjectsPage/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </ReactRoutes>
    )
}