import Icons from "./icons/Icons";

const baseUrl = process.env.PUBLIC_URL

const PROJECTS = [
    {
        portfolioData:
            {
                title: "Microservice Blog (scratch built event broker)",
                overview: "This is application was built with a microservice architecture. It serves mostly as a proof of concept and is simple in nature. The event broker is scratch built to demostrate a deeper level of understanding for the microservice architecture.",
                technologies: [Icons().titleLogos.js,Icons().titleLogos.ts, Icons().titleLogos.nodeJs,"Express.js",Icons().titleLogos.next,Icons().titleLogos.docker,Icons().titleLogos.kubernetes, Icons().titleLogos.skaffold],
                repoSrc: "https://github.com/vfalcata/blog-demo",
                videoHqSrc: "https://drive.google.com/file/d/1WB8yRPukjuHjWJRjyTqvCCmJ5OrZChdi/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/blog.webm',
                thumbnailSrc: baseUrl + '/assets/img/blog.png',
            }
    },
    {
        portfolioData:
            {
                title: "Microservice Ticketing app",
                overview: "This is a ticketing application that was built with a microservice architecture, utilizing node nats streaming as an event broker. It is fully functional with several services to take care of authentication, ticket creation, ordering, expiration, user accouts, as well as payments",
                technologies: [Icons().titleLogos.js,Icons().titleLogos.ts, Icons().titleLogos.nodeJs,"Express.js",Icons().titleLogos.next,Icons().titleLogos.docker,Icons().titleLogos.kubernetes, Icons().titleLogos.skaffold,Icons().titleLogos.redis,Icons().titleLogos.mongodb,Icons().titleLogos.nats],
                repoSrc: "https://github.com/vfalcata/tickets-demo",
                videoHqSrc: "https://drive.google.com/file/d/1sd4rcP_aAYTPClr2AkdLa2-7Rs9oxaPD/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/ticketing.webm',
                thumbnailSrc: baseUrl + '/assets/img/ticketing.png',
            }
    },    
    {
        portfolioData:
            {
                title: "E-commerce Book Store Application",
                overview: "This application is a monolithic Java web application. This was a semester long group project to develop an ecommerce app. I worked on a built in Object Relational Mapper (ORM), that conforms to the SQL specification as well as anything concerning the database or data layer. The purpose of the ORM for this project was to loosely couple database functions to the application business layer, and allow flexibility, thus it is not dependent on the SQL implementation. Access to the database is safe, stable and extensible via the ORM. The ORM was built such that auto complete guides access. Sample data and reviews were scraped using Selenuim.",
                technologies: [Icons().titleLogos.java,Icons().titleLogos.mysql,Icons().titleLogos.js,Icons().titleLogos.selenium,Icons().titleLogos.tomcat,"JSP","JSPX", "Derby"],
                repoSrc: "https://github.com/vfalcata/bookstore-demo",
                videoHqSrc: "https://drive.google.com/file/d/1lKskvoEzsPd1uC_vdTn3AIeG65K2up5m/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/bookstore.webm',
                thumbnailSrc: baseUrl + '/assets/img/bookstore.png',
            }
    },
    {
        portfolioData:
            {
                title: "Blockchain Lottery",
                overview: "A lottery application that works like a raffle, built with block chain technologies and contracts, with solidity, and etherium. It utilizes blockchain to make the lottery completely transparent, something that is not entirely possible in the same way if built using traditional paradigms. The lottery and its rules are tied to a public ethereum contract. Contestants and the winner is all public on the block chain.",
                technologies: [Icons().titleLogos.js,Icons().titleLogos.react, Icons().titleLogos.sol,Icons().titleLogos.eth],
                repoSrc: "https://github.com/vfalcata/lottery-demo",
                videoHqSrc: "https://drive.google.com/file/d/1N-x4E2csfc1Ht1oxyAtfocWOI_2y04iV/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/lottery.webm',
                thumbnailSrc: baseUrl + '/assets/img/lottery.png',
            }
    },
    {
        portfolioData:
            {
                title: "Blockchain Crowd Fund",
                overview: "A kickstarter like crowd funding application. It leverages block chain technologies and contracts in order to prevent a big issue in crowd funded projects, unwarranted use of investor funds. This is implemented such that a contract is made between the project owner and the investors as well as the payee for a resource. This contract is democratic in that investors vote to accept or decline a proposal for use of funds within the contract. If the contract proposal has the majority of investrs vote in agreement for the proposal, the funds are moved directly from the investors pool to the payee whose services were requested in the contract.",
                technologies: [Icons().titleLogos.js,Icons().titleLogos.react, Icons().titleLogos.sol,Icons().titleLogos.eth],
                repoSrc: "https://github.com/vfalcata/crowdfund-demo",
                videoHqSrc: "https://drive.google.com/file/d/1YuQm3olZTlDPW355WaA5CrS-uYo7di5z/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/crowdfund.webm',
                thumbnailSrc: baseUrl + '/assets/img/crowdfund.png',
            }
    },
    {
        portfolioData:
            {
                title: "GraphQL Parser (Open Source)",
                overview: "Scratch built to be compliant with the most recent GraphQL specification. This utility will parse the GraphQL schema file and allow you to push its output to any intermediary code generator that can generate, as an example event interfaces for multiple different services that may use different languages. GraphQL was choosen over other specificatons such as XML, JSONSchema, due to its ease of use, flexiblity and extensibility as a specification language, and also conforming end point technology. A newer vesion is planned using rust for its power in creating cli and will generate schemas instead of parsing an existing one.",
                technologies: [Icons().titleLogos.ts,Icons().titleLogos.graphQl],
                repoSrc: "https://github.com/vfalcata/graphql-schema-parser",
                videoHqSrc: "https://drive.google.com/file/d/11_cekqxh5jsKBNDi9EWEsZ0p7YedOhmJ/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/graphql.webm',
                thumbnailSrc: baseUrl + '/assets/img/graphql.png',
            }
    },
    {
        portfolioData:
            {
                title: "Smart Nordic Walking Stick, Application and Hardware",
                overview: "My capstone engineering project, that was worked on with members across different academic disciplines. Awarded the BEST award for its achievements. I worked on all the software for the application and sensor suite. I also fabricated and design the scehematics and electronics. The smart nordic walking stick is a modular device that attaches to any existing nordic walking stick, and track many vital points of data, such as positioning of sticks, heartbeat, applied force. It is fully functional and connects via bluetooth low energy.",
                technologies: [Icons().titleLogos.js, Icons().titleLogos.react,Icons().titleLogos.mongodb, Icons().titleLogos.chartjs,Icons().titleLogos.threejs, Icons().titleLogos.micropython, Icons().titleLogos.arduino, Icons().titleLogos.ble],
                repoSrc: "https://github.com/Derevi/smart-nordic-walking",
                deploymentSrc: "https://prismatic-faloodeh-351aee.netlify.app/",
                videoHqSrc: "https://drive.google.com/file/d/1Tn0pcmzQtxvG3kQeS6YAP9MPs_8XCMzC/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/nordic.webm',
                thumbnailSrc: baseUrl + '/assets/img/nordic.png',

            }
    },
    {
        portfolioData:
            {
                title: "Simple Online Food Ordering and Restaurant application",
                overview: "A simple prototype ecommerce Java Spring application, implementing a application document specification and requirement which I wrote. It uses a Java Sping backend for the api, and React.js for the front end",
                technologies: [Icons().titleLogos.react, Icons().titleLogos.js,Icons().titleLogos.mongodb, Icons().titleLogos.java,Icons().titleLogos.spring],
                repoSrc: "https://github.com/vfalcata/restaurant-demo",
                videoHqSrc: "https://drive.google.com/file/d/1hvz38J-C1nN5xNWX5hPQuAlG924gojCW/view?usp=sharing",
                videoSrc: baseUrl + '/assets/videos/restaurant.webm',
                thumbnailSrc: baseUrl + '/assets/img/restaurant.png',
            }
    },
    {
        portfolioData:
            {
                title: "Guitar Trainer (Commercial Application, In progress)",
                overview: "An application that is intended to help train player improve at playing guitar, by gamefying, guitar and theory related exercises, something that is highly neglected among guitar players. It's intention is to be a fun practice tool that can be used anywhere and does not require a guitar on hand. The intended application is built in an atypical manner, it will be a fully fledged microservice that uses Unity as a front end.",
                technologies: [Icons().titleLogos.unity,Icons().titleLogos.csharp, Icons().titleLogos.java, Icons().titleLogos.spring, Icons().titleLogos.postgresql, Icons().titleLogos.kafka],
                videoSrc: baseUrl + '/assets/videos/guitar_trainer.webm',
                thumbnailSrc: baseUrl + '/assets/img/guitar_trainer.png',
            }
    },

]

export default PROJECTS;