export const
    // Colors for navbar
    NAVBAR_BACKGROUND_COLOR = "#313131",
    NAVBAR_TITLE_FONT_COLOR = "#705d97",
    NAVBAR_LINK_FONT_COLOR = "#a4add3",
    NAVDRAWER_OPEN_ICON_COLOR = "#a4add3",
    NAVDRAWER_LINK_FONT_COLOR = "#a4add3",
    NAVDRAWER_BACKGROUND_COLOR = "#313131",
    NAVDRAWER_LOGO_COLOR = "#a4add3",
    // Colors for content body
    APP_BACKGROUND_COLOR = "#181818",
    // Portfolio Items Themeing
    PORTFOLIO_ITEM_TITLE_FONT_COLOR = "#a4add3",
    PORTFOLIO_ITEM_BODY_FONT_COLOR = "#dbe1ff",
    PORTFOLIO_ITEM_BACKGROUND_COLOR = "#454545",
    PORTFOLIO_ITEM_BORDER_COLOR = "#2b2b31",
    PORTFOLIO_ITEM_BUTTON_SELECTED_COLOR = "#8618b1",
    PORTFOLIO_ITEM_BUTTON_DESELECTED_COLOR = "#3c3c50",
    PORTFOLIO_TITLE_FONT_COLOR = "#705d97",
    PORTFOLIO_SUBTITLE_FONT_COLOR = "#705d97",

    PORTFOLIO_ITEM_BUTTON_HOVER_COLOR = "#aa63cb",
    PORTFOLIO_ITEM_BUTTON_HOVER_BACKGROUND_COLOR = "#818181",

    ABOUT_PAGE_TITLE_FONT_COLOR = "#705d97",
    ABOUT_PAGE_ITEM_TITLE_FONT_COLOR = "#a4add3",
    ABOUT_PAGE_ITEM_BODY_FONT_COLOR = "#dbe1ff",
    ABOUT_ITEM_BACKGROUND_COLOR = "#454545"

// #2b2031,#32243d,#472360,#705d97,#a4add3
// #230046, #320064
// portfolio video thumbnail is #313131
//Main color theme
// https://www.color-hex.com/color-palette/98603
// #8618b1 	(134,24,177)
// #818181 	(129,129,129)
// #454545 	(69,69,69)
// #313131 	(49,49,49)
// #181818 	(24,24,24)