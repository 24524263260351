import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faDocker,
    faJs,
    faJava,
    faReact,
    faEthereum,
    faUnity,
    faNodeJs,
    faBluetooth
} from '@fortawesome/free-brands-svg-icons'
import {GraphqlIcon} from './svgAssets/GraphqlIcon'
import {TypescriptIcon} from './svgAssets/TypescriptIcon'
import {SolidityIcon} from './svgAssets/SolidityIcon';
import {SpringIcon} from './svgAssets/SpringIcon'
import {MongoDBIcon} from './svgAssets/MongoDBIcon'
import {RedisIcon} from './svgAssets/RedisIcon'
import {CSharpIcon} from './svgAssets/CSharpIcon'
import {KubernetesIcon} from "./svgAssets/KubernetesIcon";
import {SkaffoldIcon} from "./svgAssets/SkaffoldIcon";
import {NextJsIcon} from "./svgAssets/NextJsIcon";
import {MySQLIcon} from "./svgAssets/MySQLIcon";
import {SeleniumIcon} from "./svgAssets/SeleniumIcon";
import {ChartJsIcon} from "./svgAssets/ChartJsIcon";
import {ThreeJsIcon} from "./svgAssets/ThreeJsIcon";
import {MicroPythonIcon} from "./svgAssets/MicroPythonIcon";
import {ArduinoIcon} from "./svgAssets/ArduinoIcon";
import {PostgreSQLIcon} from "./svgAssets/PostgreSQLIcon";
import {KafkaIcon} from "./svgAssets/KafkaIcon";
import {NatsIcon} from "./svgAssets/NatsIcon";
import {TomcatIcon} from "./svgAssets/TomcatIcon";
import {HaskellIcon} from "./svgAssets/HaskellIcon";
import {LispIcon} from "./svgAssets/LispIcon";
import {PythonIcon} from "./svgAssets/PythonIcon";
import {BashIcon} from "./svgAssets/BashIcon";
import {CIcon} from "./svgAssets/CIcon";

const iconLogos = (defaultColor, overrideColor) => {
    return {
        js: {
            icon: (<FontAwesomeIcon color={defaultColor ? defaultColor : "#808080"} icon={faJs}/>),
            title: "JavaScript"
        },
        java: {
            icon: (<FontAwesomeIcon color={defaultColor ? defaultColor : "#0074bd"} icon={faJava}/>),
            title: "Java"
        },
        nodeJs: {
            icon: (<FontAwesomeIcon color={defaultColor ? defaultColor : "#83cd29"} icon={faNodeJs}/>),
            title: "Node.js"
        },
        python: {
            icon: (<PythonIcon color={overrideColor}/>),
            title:"Python"
        },
        unity: {
            icon: (<FontAwesomeIcon color={defaultColor ? defaultColor : "#808080"} icon={faUnity}/>),
            title: "Unity"
        },
        react: {
            icon: (<FontAwesomeIcon color={overrideColor ? overrideColor : "#00d8ff"} icon={faReact}/>),
            title: "React"
        },
        eth: {
            icon: (<FontAwesomeIcon color={overrideColor ? overrideColor : "#473a8d"} icon={faEthereum}/>),
            title: "Ethereum"
        },
        docker: {
            icon: (<FontAwesomeIcon color={overrideColor ? overrideColor : "#2396ed"} icon={faDocker}/>),
            title: "Docker"
        },
        ble: {
            icon: (<FontAwesomeIcon color={overrideColor ? overrideColor : "#0060a9"} icon={faBluetooth}/>),
            title: "Bluetooh Low Energy"
        },
        c: {
            icon: (<CIcon color={overrideColor}/>),
            title: "C"
        },
        csharp: {
            icon: (<CSharpIcon color={defaultColor}/>),
            title: "CSharp"
        },
        sol: {
            icon: (<SolidityIcon color={defaultColor}/>),
            title: "Solidity"
        },
        mongodb: {
            icon: (<MongoDBIcon color={overrideColor}/>),
            title: "MongoDB"
        },
        graphQl: {
            icon: (<GraphqlIcon color={overrideColor}/>),
            title: "GraphQL"
        },
        ts: {
            icon: (<TypescriptIcon color={overrideColor}/>),
            title: "TypeScript"
        },
        redis: {
            icon: (<RedisIcon color={overrideColor}/>),
            title: "Redis"
        },
        spring: {
            icon: (<SpringIcon color={overrideColor}/>),
            title: "Spring"
        },
        kubernetes: {
            icon: (<KubernetesIcon color={overrideColor}/>),
            title: "Kubernetes"
        },
        skaffold: {
            icon: (<SkaffoldIcon color={overrideColor}/>),
            title: "Skaffold"
        },
        next: {
            icon: (<NextJsIcon />),
            title: "Next.jss"
        },
        mysql: {
            icon: (<MySQLIcon color={overrideColor}/>),
            title: "MySQL"
        },
        selenium: {
            icon: (<SeleniumIcon color={overrideColor}/>),
            title: "Selenium"
        },
        chartjs: {
            icon: (<ChartJsIcon color={overrideColor}/>),
            title: "Chart.js"
        },
        threejs: {
            icon: (<ThreeJsIcon color={overrideColor}/>),
            title: "Three.js"
        },
        micropython: {
            icon: (<MicroPythonIcon color={overrideColor}/>),
            title: "MicroPython"
        },
        arduino: {
            icon: (<ArduinoIcon color={overrideColor}/>),
            title: "Arduino"
        },
        postgresql: {
            icon: (<PostgreSQLIcon color={overrideColor}/>),
            title: "PostgreSQL"
        },
        kafka: {
            icon: (<KafkaIcon color={overrideColor}/>),
            title: "Apache Kafka"
        },
        nats: {
            icon: (<NatsIcon color={overrideColor}/>),
            title: "Nats Streaming"
        },
        tomcat: {
            icon: (<TomcatIcon color={overrideColor}/>),
            title: "Tomcat"
        },
        bash: {
            icon: (<BashIcon color={overrideColor}/>),
            title: "Bash"
        },
        lisp: {
            icon: (<LispIcon color={overrideColor}/>),
            title: "eLisp"
        },
        haskell: {
            icon: (<HaskellIcon color={overrideColor}/>),
            title: "Haskell"
        }
    }
}

const Icons = (defaultColor, overrideColor) => {
    let titleLogos = {}
    let icons = {}
    let title = {}
    for (const icon in iconLogos()) {
        titleLogos[icon] = (<>{iconLogos(defaultColor, overrideColor)[icon].icon} {iconLogos(defaultColor, overrideColor)[icon].title}</>)
    }
    for (const icon in iconLogos()) {
        icons[icon] = iconLogos(defaultColor, overrideColor)[icon].icon
    }
    for (const icon in iconLogos()) {
        title[icon] = iconLogos(defaultColor, overrideColor)[icon].title
    }
    return {
        icons,
        title,
        titleLogos
    }
}
export default Icons;