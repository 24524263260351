import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import {Box, Grid} from "@mui/material";
import SchemaIcon from '@mui/icons-material/Schema';
import PortfolioVideo from "./PortfolioVideo";
import TerminalIcon from '@mui/icons-material/Terminal';
import {useState} from "react";
import Typography from "@mui/material/Typography";
import LandscapeIcon from '@mui/icons-material/Landscape';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
    PORTFOLIO_ITEM_TITLE_FONT_COLOR,
    PORTFOLIO_ITEM_BODY_FONT_COLOR,
    PORTFOLIO_ITEM_BACKGROUND_COLOR,
    PORTFOLIO_ITEM_BORDER_COLOR,
    PORTFOLIO_ITEM_BUTTON_SELECTED_COLOR,
    PORTFOLIO_ITEM_BUTTON_DESELECTED_COLOR,
    PORTFOLIO_ITEM_BUTTON_HOVER_BACKGROUND_COLOR,
    PORTFOLIO_ITEM_BUTTON_HOVER_COLOR,
} from "../../COLOR_THEME";

const OVERVIEW_CONTENT = "overview"
const SRC_CONTENT = "src"
const STACK_CONTENT = "stack"

export default function PortfolioItem({portfolioData}) {
    const [contentSwitch, setContentSwitch] = useState(OVERVIEW_CONTENT)

    const getDeploymentSrc = () => {
        if (portfolioData.deploymentSrc) {
            return (
                <Grid item xs={12}>
                    <a href={portfolioData.deploymentSrc}>
                        <OpenInBrowserIcon/> Deployed Application
                    </a>
                </Grid>
            )
        }
    }

    const getRepoSrc = () => {
        if (portfolioData.repoSrc) {
            return (
                <Grid item xs={12}>
                    <a href={portfolioData.repoSrc}>
                        <GitHubIcon/> Repository
                    </a>
                </Grid>
            )
        } else {
            return (
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="div" sx={{color: PORTFOLIO_ITEM_TITLE_FONT_COLOR}}>
                        This is a commercial application and is currently being worked on.
                    </Typography>
                </Grid>
            )
        }
    }

    const getVideoHqSrc = () => {
        if (portfolioData.videoHqSrc) {
            return (
                <Grid item xs={12}>
                    <a href={portfolioData.videoHqSrc}>
                        <OndemandVideoIcon/> Link to HQ Video Demo
                    </a>
                </Grid>
            )
        }
    }


    const getText = () => {
        if (SRC_CONTENT === contentSwitch) {
            return (<div>
                <Grid spacing={6} container>
                    {getRepoSrc()}
                    {getDeploymentSrc()}
                    {getVideoHqSrc()}
                </Grid>
            </div>)
        } else if (STACK_CONTENT === contentSwitch) {
            const technologies = portfolioData.technologies.map(technology =>
                <Grid item xs={4}>
                    <Typography variant="h6"
                                display="block"
                                gutterBottom
                                sx={{color: PORTFOLIO_ITEM_BODY_FONT_COLOR}}>
                        {technology}
                    </Typography>
                </Grid>
            )
            return (
                <Grid container spacing={2}>
                    {technologies}
                </Grid>
            )
        } else {
            return (
                <>
                    <Typography gutterBottom variant="h5" component="div" sx={{color: PORTFOLIO_ITEM_TITLE_FONT_COLOR}}>
                        {portfolioData.title}
                    </Typography>
                    <Typography variant="body2" sx={{color: PORTFOLIO_ITEM_BODY_FONT_COLOR}}>
                        {portfolioData.overview}
                    </Typography>
                </>
            )
        }
    }

    const portfolioButtons = [
        {
            title: "overview",
            icon: (<LandscapeIcon/>),
            content: OVERVIEW_CONTENT
        },

        {
            title: "technology stack",
            icon: (<SchemaIcon/>),
            content: STACK_CONTENT
        },
        {
            title: "source code",
            icon: (<TerminalIcon/>),
            content: SRC_CONTENT
        }
    ]

    const buttons = () => {
        let items = []
        portfolioButtons.forEach(element => {
            if (element.content === contentSwitch) {
                items.push(
                    <Button onClick={() => setContentSwitch(element.content)} title={element.title} sx={{
                        m: 1,
                        bgcolor: PORTFOLIO_ITEM_BACKGROUND_COLOR,
                        color: PORTFOLIO_ITEM_BUTTON_SELECTED_COLOR,
                        borderRadius: 25,
                        border: 1.8,
                        boxShadow: 3,
                        width: 25,
                        height: 60,
                        ':hover': {
                            bgcolor: PORTFOLIO_ITEM_BUTTON_HOVER_BACKGROUND_COLOR,
                            color: PORTFOLIO_ITEM_BUTTON_HOVER_COLOR
                        }
                    }}>{element.icon}</Button>
                )
            } else {
                items.push(
                    <Button onClick={() => setContentSwitch(element.content)} title={element.title} sx={{
                        m: 1,
                        bgcolor: PORTFOLIO_ITEM_BACKGROUND_COLOR,
                        color: PORTFOLIO_ITEM_BUTTON_DESELECTED_COLOR,
                        borderRadius: 25,
                        border: 1.8,
                        boxShadow: 3,
                        width: 25,
                        height: 60,
                        ':hover': {
                            bgcolor: PORTFOLIO_ITEM_BUTTON_HOVER_BACKGROUND_COLOR,
                            color: PORTFOLIO_ITEM_BUTTON_HOVER_COLOR
                        }
                    }}>{element.icon}</Button>
                )
            }
        })
        return items
    }
    return (
        <Box>
            <Card sx={{
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                color: PORTFOLIO_ITEM_BORDER_COLOR,
                border: 1.8,
                bgcolor: PORTFOLIO_ITEM_BACKGROUND_COLOR,
                boxShadow: 3,
                minWidth: 324
            }}>
                <CardMedia>
                    <PortfolioVideo videoSrc={portfolioData.videoSrc} thumbnailSrc={portfolioData.thumbnailSrc}/>
                </CardMedia>
                <CardContent sx={{height: 380}}>

                    {getText()}
                </CardContent>
            </Card>
            <Card sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                border: 1.8,
                borderTop: 0,
                color: PORTFOLIO_ITEM_BORDER_COLOR,
                bgcolor: PORTFOLIO_ITEM_BACKGROUND_COLOR,
                boxShadow: 3,
                minWidth: 324
            }}>
                {buttons()}

            </Card>
        </Box>
    )
}


