import HoverVideoPlayer from "react-hover-video-player";

export default function PortfolioVideo({videoSrc, thumbnailSrc}) {
    return (
        <HoverVideoPlayer
            //this will make a video play or not
            // disableDefaultEventHandling={disableVideo}
            videoSrc={videoSrc}
            restartOnPaused
            pausedOverlay={
                <img
                    src={thumbnailSrc}
                    alt=""
                    style={{
                        // Make the image expand to cover the video's dimensions
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            }
            loadingOverlay={
                <div className="loading-overlay">
                    <div className="loading-spinner"/>
                </div>
            }
        />
    );
}