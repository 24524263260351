import PROJECTS from "../constants/PROJECTS";
import {Grid} from "@mui/material";
import PortfolioItem from "../structures/portfolio/PortfolioItem";
import Typography from "@mui/material/Typography";
import {PORTFOLIO_TITLE_FONT_COLOR, PORTFOLIO_SUBTITLE_FONT_COLOR} from "../COLOR_THEME";

export const ProjectsPage = () => {
    const items = [];
    items.push(
        <Grid xs={12} item>
            <Typography gutterBottom variant="h5" sx={{color: PORTFOLIO_TITLE_FONT_COLOR}}>
                Please browse my works
            </Typography>
        </Grid>
    )
    items.push(
        <Grid xs={0.5} sm={0.5} md={0.5} lg={3} xl={3}  item/>
    )
    items.push(
        <Grid xs={11} sm={11} md={11} lg={6} xl={6}  item>
            <Typography variant="body2" sx={{color: PORTFOLIO_SUBTITLE_FONT_COLOR}}>
                Hover your cursor over a project thumbnail to see a video demo. The hosting service is not perfect, but
                it
                is free, so if a video demo does not automatically play when your cursor is above the project thumbnail,
                give it some time, or refresh. In progress commercial applications do not have a video, only a mock up
                image.
            </Typography>
        </Grid>
    )
    items.push(
        <Grid xs={0.5} sm={0.5} md={0.5} lg={3} xl={3} item/>
    )
    PROJECTS.forEach(element => {
        items.push(<Grid item sm={12} md={6} lg={6} xl={4} xs={12}><PortfolioItem
            portfolioData={element.portfolioData}/></Grid>)
    })
    return (<Grid container spacing={4} p={4}>{items}</Grid>)
}